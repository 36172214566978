/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_notes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18 0a4 4 0 014 4v16a4 4 0 01-4 4H6a4 4 0 01-4-4V4a4 4 0 014-4h12zm0 1H6a3 3 0 00-3 3v16a3 3 0 003 3h12a3 3 0 003-3V4a3 3 0 00-3-3zm-4.75 13.5a.5.5 0 01.09.992l-.09.008h-6.5a.5.5 0 01-.09-.992l.09-.008h6.5zm4-4a.5.5 0 110 1H6.75a.5.5 0 110-1zm-10.5-4h10.5a.5.5 0 01.09.992l-.09.008H6.75a.5.5 0 01-.09-.992l.09-.008h10.5z" id="svgicon__notes_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__notes_b" _fill="#fff"><use xlink:href="#svgicon__notes_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__notes_a"/><g mask="url(#svgicon__notes_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
