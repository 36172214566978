/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_menu_horiz_hover': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M2 0a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4zm8 0a2 2 0 110 4 2 2 0 010-4z" id="svgicon__menu_horiz_hover_a"/></defs><g _fill="none" fill-rule="evenodd"><path pid="1" _fill="#FFF" d="M0 0h24v24H0z"/><g transform="translate(2 10)"><mask id="svgicon__menu_horiz_hover_b" _fill="#fff"><use xlink:href="#svgicon__menu_horiz_hover_a"/></mask><use _fill="#3773F5" xlink:href="#svgicon__menu_horiz_hover_a"/><g mask="url(#svgicon__menu_horiz_hover_b)" _fill="#3773F5"><path pid="2" d="M-2-10h24v24H-2z"/></g></g></g>'
  }
})
