/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'app_icon_customer': {
    width: 158,
    height: 140,
    viewBox: '0 0 158 140',
    data: '<defs><linearGradient x1="20.315%" y1="84.111%" x2="82.895%" y2="19.719%" id="svgicon_app_icon_customer_a"><stop stop-color="#0ABF53" offset="0%"/><stop stop-color="#12D360" offset="100%"/></linearGradient></defs><path pid="0" d="M73.69.018c21.505 0 40.858 8.774 54.327 23.054 8.223-3.598 17.138-5.19 26.742-4.777l.75.036a2 2 0 011.573 3.081c-8.266 12.815-11.786 25.44-10.561 37.878.074.758.096 1.464.065 2.12.512 3.482.777 7.056.777 10.704 0 40.689-32.984 67.368-73.672 67.368-40.688 0-73.673-26.68-73.673-67.368C.018 31.426 33.003.018 73.691.018zM34.415 82.91c-6.072 10.493 17.52 32 39.13 32 21.612 0 46.287-22.266 39.131-32-7.156-9.734-19.565 0-39.13 0-19.566 0-33.06-10.493-39.13 0zm69.021-44.934c-9.79 0-17.728 6.937-17.728 16.729 0 9.79 7.937 3.728 17.728 3.728 9.792 0 17.73 6.063 17.73-3.728 0-9.792-7.938-16.73-17.73-16.73zm-59.49 0c-9.79 0-17.728 6.937-17.728 16.729 0 9.79 7.938 3.728 17.729 3.728s17.729 6.063 17.729-3.728c0-9.792-7.938-16.73-17.73-16.73z" _fill="url(#svgicon_app_icon_customer_a)" fill-rule="nonzero"/>'
  }
})
