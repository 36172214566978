/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_business_admin': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#3773F5" opacity=".1" cx="28" cy="28" r="28"/><path pid="1" d="M28 8c11.046 0 20 8.954 20 20s-8.954 20-20 20S8 39.046 8 28 16.954 8 28 8zm0 1.905C18.006 9.905 9.905 18.006 9.905 28c0 9.994 8.101 18.095 18.095 18.095 9.994 0 18.095-8.101 18.095-18.095 0-9.994-8.101-18.095-18.095-18.095zm.028 21.739c4.202 0 7.998 1.974 9.845 5.065a.952.952 0 11-1.635.977c-1.489-2.492-4.653-4.137-8.21-4.137-3.599 0-6.793 1.685-8.259 4.22a.952.952 0 01-1.649-.953c1.82-3.15 5.655-5.172 9.908-5.172zM28 16.823a6.716 6.716 0 110 13.432 6.716 6.716 0 010-13.432zm0 1.904a4.811 4.811 0 100 9.623 4.811 4.811 0 000-9.623z" _fill="#3773F5" fill-rule="nonzero" opacity=".5"/><g transform="translate(34.616 33)"><circle pid="2" _fill="#3773F5" cx="6.5" cy="6.5" r="6.5"/><path pid="3" _fill="#FFF" d="M6.5 8.5L4.149 9.736l.449-2.618-1.902-1.854 2.628-.382L6.5 2.5l1.176 2.382 2.628.382-1.902 1.854.45 2.618z"/></g></g>'
  }
})
