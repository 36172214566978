/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'voice_record': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<g transform="translate(.61 .61)" _fill="#3773F5" fill-rule="evenodd"><circle pid="0" fill-opacity=".1" cx="14.391" cy="14.391" r="14.391"/><path pid="1" d="M14.39 4.89a3 3 0 013 3v7a3 3 0 01-6 0v-7a3 3 0 013-3zm5.25 6.5a.75.75 0 00-.75.75v2.75a4.5 4.5 0 11-9 0v-2.75a.75.75 0 00-1.5 0v2.75a6 6 0 005.25 5.95v1.55h-2a.75.75 0 100 1.5h5.5a.75.75 0 100-1.5h-2v-1.55a6 6 0 005.25-5.95v-2.75a.75.75 0 00-.75-.75z"/></g>'
  }
})
