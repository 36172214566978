/* eslint-disable */
require('./_add_hover')
require('./_add_person')
require('./_add')
require('./_added')
require('./_avatar_placeholder')
require('./_bookmark_active')
require('./_bookmark')
require('./_business_admin')
require('./_business')
require('./_case_info')
require('./_chat')
require('./_check')
require('./_checked')
require('./_close')
require('./_contact')
require('./_create_chat')
require('./_customer_notes')
require('./_dashboard')
require('./_default_2')
require('./_enterprise')
require('./_faq')
require('./_forward')
require('./_images')
require('./_invisible_contact')
require('./_link')
require('./_location_pin')
require('./_location')
require('./_marked')
require('./_members')
require('./_menu_horiz_hover')
require('./_menu_horiz')
require('./_notes')
require('./_notification')
require('./_notifications_profile')
require('./_options_menu')
require('./_pigeon_logo')
require('./_privacy')
require('./_profile')
require('./_rating_star')
require('./_request')
require('./_requests')
require('./_search')
require('./_send_contacts')
require('./_send')
require('./_settings')
require('./_wroking_hours')
require('./app_icon_customer')
require('./car_category')
require('./default-white')
require('./icon_search')
require('./logo_discovery')
require('./logo')
require('./minimized')
require('./notifications')
require('./voice_record')
