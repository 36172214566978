/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_enterprise': {
    width: 22,
    height: 23,
    viewBox: '0 0 22 23',
    data: '<g _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M11 7.737c1.234 0 2.37.589 3.087 1.565a.5.5 0 01-.806.591A2.822 2.822 0 0011 8.737c-.912 0-1.75.434-2.28 1.155a.5.5 0 01-.805-.592A3.821 3.821 0 0111 7.737zM11 2.3a2.317 2.317 0 100 4.633A2.317 2.317 0 0011 2.3zm0 1a1.317 1.317 0 110 2.633A1.317 1.317 0 0111 3.3zM3 18a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11 18a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"/><path pid="1" d="M11 0a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm0 1a5.5 5.5 0 110 11 5.5 5.5 0 010-11zM19 18a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm0 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"/><path pid="2" d="M16.263 14.876a3.237 3.237 0 013.232 3.053l.005.184h-1c0-1.182-.916-2.15-2.077-2.232l-.16-.005H5.737c-1.182 0-2.15.916-2.231 2.077l-.006.16h-1a3.237 3.237 0 013.053-3.232l.184-.005h10.526z"/><path pid="3" d="M11.5 12.639v5.474h-1v-5.474z"/></g>'
  }
})
