/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_bookmark': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M10 5a3 3 0 00-3 3v19.451a1 1 0 001.676.737l7.122-6.533 7.54 6.66a1 1 0 001.662-.75V8a3 3 0 00-3-3H10z" _stroke="#FFF" stroke-width="2" _fill="none" fill-rule="evenodd" opacity=".5"/>'
  }
})
