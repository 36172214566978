/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_contact': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17 1.5A4.5 4.5 0 0121.5 6v12a4.5 4.5 0 01-4.5 4.5H8A4.5 4.5 0 013.5 18a.5.5 0 111 0A3.5 3.5 0 008 21.5h9a3.5 3.5 0 003.5-3.5V6A3.5 3.5 0 0017 2.5H8A3.5 3.5 0 004.5 6a.5.5 0 01-1 0A4.5 4.5 0 018 1.5zm-4.26 12.172c2.002 0 3.812.94 4.694 2.417a.5.5 0 11-.858.513c-.694-1.161-2.173-1.93-3.836-1.93-1.684 0-3.176.787-3.86 1.969a.5.5 0 01-.865-.5c.87-1.505 2.698-2.47 4.725-2.47zM5 14.225a.5.5 0 110 1H3a.5.5 0 110-1zm7.727-7.556a3.223 3.223 0 110 6.447 3.223 3.223 0 010-6.447zm0 1a2.223 2.223 0 100 4.447 2.223 2.223 0 000-4.447zM5 11.112a.5.5 0 110 1H3a.5.5 0 110-1zM5 8a.5.5 0 010 1H3a.5.5 0 010-1z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
