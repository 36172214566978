/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_add_person': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.524 14.98a.5.5 0 01.5.5l-.001 3.499h3.5a.5.5 0 01.09.992l-.09.008h-3.5v3.5a.5.5 0 01-.41.492l-.09.008a.5.5 0 01-.5-.5v-3.5h-3.5a.5.5 0 01-.09-.991l.09-.009h3.5v-3.5a.5.5 0 01.41-.491zM8.035 11.5c3.392 0 6.446 1.644 7.922 4.2a.5.5 0 01-.866.5c-1.292-2.237-4.01-3.7-7.056-3.7-3.081 0-5.827 1.499-7.098 3.776a.5.5 0 01-.873-.488C1.518 13.184 4.603 11.5 8.035 11.5zM8.012 0a5 5 0 110 10 5 5 0 010-10zm0 1a4 4 0 100 8 4 4 0 000-8z" id="svgicon__add_person_a"/></defs><g transform="translate(-.012)" _fill="none" fill-rule="evenodd"><mask id="svgicon__add_person_b" _fill="#fff"><use xlink:href="#svgicon__add_person_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__add_person_a"/><g mask="url(#svgicon__add_person_b)" _fill="#3773F5"><path pid="1" d="M.012 0h24v24h-24z"/></g></g>'
  }
})
