/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_send': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M-1.557 5.537l18.99-4.72a2 2 0 012.415 2.459l-4.96 18.485a2 2 0 01-3.893-.125l-2.053-10.24-10.386-1.953a2 2 0 01-.113-3.906zm-.5 1.756a1 1 0 00.798 1.168L9.79 10.539l2.186 10.9a1 1 0 001.946.063l4.96-18.485a1 1 0 00-1.207-1.23l-18.99 4.72a1 1 0 00-.742.786z" id="svgicon__send_a"/></defs><use _fill="#3773F5" fill-rule="nonzero" transform="rotate(45 8.42 12)" xlink:href="#svgicon__send_a"/>'
  }
})
