/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_search': {
    width: 16,
    height: 16,
    viewBox: '0 0 18 18',
    data: '<defs><path pid="0" id="svgicon_icon_search_a" d="M14 17.142l.001.048v4.818a1 1 0 01-1 1h-.727a1 1 0 01-1-1V17.19l.001-.048a7.5 7.5 0 112.725 0zm-1.363-1.922a5.454 5.454 0 100-10.909 5.454 5.454 0 000 10.909z"/></defs><g _fill="#0ABF53" transform="translate(-3 -3)" fill-rule="evenodd"><mask id="svgicon_icon_search_b" _fill="#fff"><use xlink:href="#svgicon_icon_search_a"/></mask><use transform="rotate(-45 12.637 12.637)" fill-rule="nonzero" xlink:href="#svgicon_icon_search_a"/><g mask="url(#svgicon_icon_search_b)"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
