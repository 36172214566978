/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M16.62 13.316L8.923 23l-7.004-9.048a8.693 8.693 0 01-.295-.381l-.15-.194.007-.008A8.335 8.335 0 010 8.627C0 3.863 4.03 0 9 0s9 3.863 9 8.627a8.33 8.33 0 01-1.414 4.645l.035.044zm-1.21-.083l.344-.516A7.329 7.329 0 0017 8.627C17 4.425 13.428 1 9 1S1 4.425 1 8.627c0 1.505.457 2.943 1.304 4.175l.092.133.035.046c.083.114.17.227.278.359l6.224 8.04 6.476-8.147zM9 11.1c-1.42 0-2.571-1.106-2.571-2.472 0-1.365 1.15-2.472 2.571-2.472 1.42 0 2.571 1.107 2.571 2.472 0 1.366-1.15 2.472-2.571 2.472zm0-1c.876 0 1.571-.668 1.571-1.472 0-.804-.695-1.472-1.571-1.472s-1.571.668-1.571 1.472c0 .804.695 1.472 1.571 1.472z" id="svgicon__location_a"/></defs><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__location_a" transform="translate(3 .5)"/>'
  }
})
