/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_forward': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="none" fill-rule="evenodd" _stroke="#3773F5" stroke-linecap="round"><path pid="0" d="M23 6H8a8 8 0 100 16h16" stroke-linejoin="round"/><path pid="1" d="M19.5 2l4 4.5-4 4.5"/></g>'
  }
})
