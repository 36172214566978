/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_privacy': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 .5a6.5 6.5 0 016.496 6.267L18.5 7H20a4 4 0 014 4v8a4 4 0 01-4 4H4a4 4 0 01-4-4v-8a4 4 0 014-4h1.5A6.5 6.5 0 0112 .5zM20 8H4a3 3 0 00-3 3v8a3 3 0 003 3h16a3 3 0 003-3v-8a3 3 0 00-3-3zM8 15a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zm4 0a1 1 0 110 2 1 1 0 010-2zM12 1.5a5.5 5.5 0 00-5.496 5.279L6.5 7h11A5.5 5.5 0 0012 1.5z" id="svgicon__privacy_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__privacy_b" _fill="#fff"><use xlink:href="#svgicon__privacy_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__privacy_a"/><g mask="url(#svgicon__privacy_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
