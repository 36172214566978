/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_rating_star': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 23.25l-4.936 2.595a2 2 0 01-2.902-2.108l.943-5.497-3.993-3.892a2 2 0 011.108-3.411l5.519-.802 2.468-5.001a2 2 0 013.586 0l2.468 5 5.519.803a2 2 0 011.108 3.41l-3.993 3.893.943 5.497a2 2 0 01-2.902 2.108L16 23.25z" _fill="#FFF624" fill-rule="evenodd"/>'
  }
})
