/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_send_contacts': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.856-.002c2.221 0 4.024 1.83 4.131 4.117l.005.216v15.333c0 2.315-1.735 4.215-3.93 4.328l-.206.006H7.129c-2.291 0-4.137-1.946-4.137-4.334a.5.5 0 011 0c0 1.783 1.315 3.23 2.96 3.328l.177.006h12.727c1.666 0 3.039-1.386 3.131-3.144l.005-.19V4.331c0-1.783-1.314-3.23-2.959-3.328l-.177-.005H7.129c-1.667 0-3.04 1.386-3.132 3.143l-.005.19v.958A.5.5 0 013 5.38l-.008-.09v-.958c0-2.315 1.736-4.215 3.93-4.328l.207-.005h12.727zM14.009 14.5c2.525 0 4.804 1.185 5.911 3.037a.5.5 0 01-.858.513c-.92-1.537-2.867-2.55-5.053-2.55-2.212 0-4.178 1.037-5.083 2.602a.5.5 0 01-.866-.5c1.091-1.888 3.393-3.102 5.95-3.102zm-8.517 1a.5.5 0 110 1h-4a.5.5 0 110-1zm8.5-10a4 4 0 110 8 4 4 0 010-8zm0 1a3 3 0 100 6 3 3 0 000-6zm-12.5 5h4a.5.5 0 01.09.992l-.09.008h-4a.5.5 0 01-.09-.992l.09-.008h4zm4-4a.5.5 0 01.09.992l-.09.008h-4a.5.5 0 01-.09-.992l.09-.008h4z" id="svgicon__send_contacts_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__send_contacts_b" _fill="#fff"><use xlink:href="#svgicon__send_contacts_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__send_contacts_a"/><g mask="url(#svgicon__send_contacts_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
