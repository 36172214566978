/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'car_category': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" cx="12" cy="12" r="12" _fill="#0ABF53" opacity=".2"/><path pid="1" d="M3.738 10.889l1.471-.672 1.298-2.071a2.045 2.045 0 011.745-.96h5.532c.641 0 1.25.296 1.64.806l1.677 2.195 2.795.766c.71.195 1.201.844 1.201 1.58v1.07c0 .903-.72 1.65-1.625 1.65h-.24a2.083 2.083 0 01-4.034 0H8.894a2.083 2.083 0 01-4.034 0h-.45c-.905 0-1.625-.747-1.625-1.65v-1.22a1.635 1.635 0 01.953-1.494zm13.479 5.167a1.332 1.332 0 100-2.665 1.332 1.332 0 000 2.665zm-10.338 0a1.332 1.332 0 100-2.665 1.332 1.332 0 000 2.665zm-2.47-1.553h.399a2.081 2.081 0 012.3-1.843 2.08 2.08 0 011.842 1.843h6.196a2.081 2.081 0 012.3-1.843 2.08 2.08 0 011.843 1.843h.187c.492 0 .875-.41.875-.9v-1.07a.884.884 0 00-.65-.86l-2.923-.799a.366.366 0 01-.199-.135l-1.748-2.293a1.326 1.326 0 00-1.047-.51H8.253a1.293 1.293 0 00-1.111.608l-1.359 2.169a.37.37 0 01-.16.142l-1.57.717a.884.884 0 00-.513.81v1.22h-.004c0 .492.382.9.874.9z" _fill="#0ABF53" fill-rule="nonzero"/><path pid="2" d="M5.523 10.508c1.722-.196 3.444-.294 5.166-.294s3.758.098 6.107.294" _stroke="#0ABF53" stroke-width=".7"/></g>'
  }
})
