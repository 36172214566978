/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_check': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm7.425 5.818a.5.5 0 01.057.638l-.057.07-9.193 9.192a.498.498 0 01-.226.13l-.085.014h-.085a.498.498 0 01-.31-.144l-4.95-4.95a.5.5 0 01.706-.707l4.597 4.596 8.839-8.839a.5.5 0 01.707 0z" id="svgicon__check_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__check_b" _fill="#fff"><use xlink:href="#svgicon__check_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__check_a"/><g mask="url(#svgicon__check_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
