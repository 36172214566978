/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifications': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.903 17.888a.4.4 0 11.565.565 3.233 3.233 0 01-4.356.198h0l-.208-.174a.4.4 0 11.511-.613h0l.208.173c.967.806 2.39.742 3.28-.149zM10.126.6a5.97 5.97 0 015.97 5.97v3.117a4.83 4.83 0 002.236 4.075l.109.069a1.198 1.198 0 01-.643 2.208H2.202a1.198 1.198 0 01-.6-2.234l.09-.052A4.937 4.937 0 004.155 9.48V6.57A5.97 5.97 0 0110.126.6zm0 .799a5.171 5.171 0 00-5.171 5.17V9.48a5.735 5.735 0 01-2.864 4.964l-.089.052a.4.4 0 00.2.745h15.596a.4.4 0 00.214-.736l-.108-.07a5.629 5.629 0 01-2.607-4.748V6.57a5.171 5.171 0 00-5.171-5.171z" _fill="#FFF" fill-rule="nonzero" _stroke="#FFF" stroke-width=".5"/>'
  }
})
