/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_wroking_hours': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0c6.627 0 12 5.373 12 12a.5.5 0 11-1 0c0-6.075-4.925-11-11-11S1 5.926 1 12s4.925 11 11 11a.5.5 0 110 1C5.373 24 0 18.626 0 12S5.374 0 12 0zm7.012 20.426c1.883 0 3.586.885 4.416 2.275a.5.5 0 11-.858.513c-.643-1.075-2.014-1.788-3.558-1.788-1.562 0-2.946.73-3.578 1.824a.5.5 0 11-.866-.5c.819-1.417 2.539-2.324 4.444-2.324zM19 13.881a3.045 3.045 0 110 6.09 3.045 3.045 0 010-6.09zm0 1a2.045 2.045 0 100 4.09 2.045 2.045 0 000-4.09zm-7-9.929a.5.5 0 01.5.5v7.244l-4.212 3.03a.5.5 0 01-.584-.812l3.796-2.73V5.451a.5.5 0 01.5-.5z" _fill="#3773F5" fill-rule="nonzero"/>'
  }
})
