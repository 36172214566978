/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_avatar_placeholder': {
    width: 74,
    height: 74,
    viewBox: '0 0 74 74',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#3773F5" opacity=".1" cx="37" cy="37" r="37"/><path pid="1" d="M37 17c11.046 0 20 8.954 20 20s-8.954 20-20 20-20-8.954-20-20 8.954-20 20-20zm0 1.905c-9.994 0-18.095 8.101-18.095 18.095 0 9.994 8.101 18.095 18.095 18.095 9.994 0 18.095-8.101 18.095-18.095 0-9.994-8.101-18.095-18.095-18.095zm.028 21.739c4.202 0 7.998 1.974 9.845 5.065a.952.952 0 11-1.635.977c-1.489-2.492-4.653-4.137-8.21-4.137-3.599 0-6.793 1.685-8.259 4.22a.952.952 0 01-1.649-.953c1.82-3.15 5.655-5.172 9.908-5.172zM37 25.823a6.716 6.716 0 110 13.432 6.716 6.716 0 010-13.432zm0 1.904a4.811 4.811 0 100 9.623 4.811 4.811 0 000-9.623z" _fill="#3773F5" fill-rule="nonzero"/><g transform="translate(41 41)" fill-rule="nonzero"><circle pid="2" _fill="#3773F5" cx="8" cy="8" r="8"/><path pid="3" d="M8 4.623c.31 0 .56.25.56.56V7.44h2.257a.56.56 0 010 1.12H8.56v2.257a.56.56 0 01-1.12 0V8.56H5.183a.56.56 0 010-1.12H7.44V5.183c0-.31.25-.56.56-.56z" _fill="#FFF"/></g></g>'
  }
})
