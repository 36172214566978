/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_invisible_contact': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g _fill="none" fill-rule="evenodd"><circle pid="0" _fill="#A4A4A4" cx="9" cy="9" r="9"/><path pid="1" d="M9 12.706c3.216 0 5.824-3.085 5.824-3.706 0-.62-2.608-3.706-5.824-3.706-3.216 0-5.824 3.234-5.824 3.706S5.784 12.706 9 12.706z" _fill="#FFF"/><circle pid="2" _fill="#A4A4A4" cx="8.676" cy="9" r="2.324"/><circle pid="3" _fill="#FFF" cx="7.676" cy="8" r="1.324"/></g>'
  }
})
