/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo_discovery': {
    width: 223,
    height: 215,
    viewBox: '0 0 223 215',
    data: '<g _fill="none" fill-rule="evenodd"><path pid="0" d="M227.991-170.944L385.944-12.991c16.012 16.011 16.012 41.97 0 57.982L227.991 202.944c-16.011 16.012-41.97 16.012-57.982 0L12.056 44.991c-16.012-16.011-16.012-41.97 0-57.982l157.953-157.953c16.011-16.012 41.97-16.012 57.982 0z" _fill="#0ABF53"/><g transform="translate(23.966 44.332)"><ellipse pid="1" _fill="#000" fill-rule="nonzero" opacity=".1" cx="17.735" cy="77.078" rx="17.735" ry="1.73"/><ellipse pid="2" _fill="#000" fill-rule="nonzero" opacity=".1" cx="147.301" cy="107.681" rx="20.198" ry="1.971"/><path pid="3" d="M56.176 63.906c-7.17 0-13.906-1.47-19.748-4.05-.025-.01-6.437 3.624-19.239 10.903 3.505-14.076 5.256-21.115 5.253-21.118-4.275-5.064-6.766-11.147-6.766-17.688C15.676 14.306 33.808 0 56.176 0s40.5 14.306 40.5 31.953-18.132 31.953-40.5 31.953z" _fill="#F0F0F0" fill-rule="nonzero"/><g transform="translate(37.09 23.276)" _fill="#C7C7CD"><rect pid="4" width="38" height="4" rx="2"/><path pid="5" d="M1.5 12h20a1.5 1.5 0 010 3h-20a1.5 1.5 0 010-3z"/></g><g><path pid="6" d="M108.314 93.7c7.17 0 13.906-1.471 19.748-4.05.024-.01 6.437 3.623 19.239 10.902a19109.884 19109.884 0 01-5.253-21.117c4.275-5.065 6.766-11.147 6.766-17.689 0-17.647-18.133-31.953-40.5-31.953-22.368 0-40.5 14.306-40.5 31.953S85.946 93.7 108.314 93.7z" _fill="#6C63FF" fill-rule="nonzero"/><g transform="translate(89.228 48.712)" _fill="#3F3D56" opacity=".6"><rect pid="7" width="38" height="4" rx="2"/><rect pid="8" y="12" width="38" height="3" rx="1.5"/><path pid="9" d="M1.5 23h13a1.5 1.5 0 010 3h-13a1.5 1.5 0 010-3z"/></g></g></g></g>'
  }
})
