/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_options_menu': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 10a2 2 0 110 4 2 2 0 010-4zm-8 0a2 2 0 110 4 2 2 0 010-4zm-8 0a2 2 0 110 4 2 2 0 010-4z" _fill="#3773F5" fill-rule="nonzero"/>'
  }
})
