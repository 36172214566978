/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_add_hover': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 4a.5.5 0 01.5.5v5h5a.5.5 0 110 1h-5v5a.5.5 0 11-1 0v-5.001l-5 .001a.5.5 0 110-1l5-.001V6.5A.5.5 0 0112 6z" id="svgicon__add_hover_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__add_hover_b" _fill="#fff"><use xlink:href="#svgicon__add_hover_a"/></mask><use _fill="#3773F5" opacity=".2" xlink:href="#svgicon__add_hover_a"/><g opacity=".2" mask="url(#svgicon__add_hover_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
