/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs><path pid="0" d="M15.257 3.772v7.5h7.5v3h-7.5v7.5h-3v-7.5h-7.5v-3h7.5v-7.5h3z" id="svgicon__close_a"/></defs><use _fill="#3773F5" transform="rotate(45 16.661 3.383)" xlink:href="#svgicon__close_a" fill-rule="evenodd"/>'
  }
})
