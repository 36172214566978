/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_notifications_profile': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<g _fill="#3773F5" fill-rule="evenodd"><circle pid="0" cx="28" cy="28" r="28" opacity=".1"/><path pid="1" d="M25.311 37.285a.5.5 0 01.704-.064l.26.217a3.049 3.049 0 004.108-.186.5.5 0 01.708.707 4.049 4.049 0 01-5.455.247l-.26-.217a.5.5 0 01-.065-.704zm2.846-20.43a7.475 7.475 0 017.476 7.476v3.903a6.048 6.048 0 002.801 5.103l.135.086a1.5 1.5 0 01-.805 2.766H18.236a1.5 1.5 0 01-.751-2.799l.11-.064a6.182 6.182 0 003.087-5.351V24.33a7.475 7.475 0 017.475-7.476zm0 1a6.475 6.475 0 00-6.475 6.476v3.644a7.182 7.182 0 01-3.586 6.217l-.11.064a.5.5 0 00.25.933h19.528a.5.5 0 00.269-.922l-.136-.086a7.048 7.048 0 01-3.264-5.947v-3.903c0-3.577-2.9-6.476-6.476-6.476z" fill-rule="nonzero"/></g>'
  }
})
