/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_dashboard': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" d="M3 9.132a1 1 0 011 1v7a1 1 0 01-1 1H1a1 1 0 01-1-1v-7a1 1 0 011-1h2zm14-4.548a1 1 0 011 1v11.5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-11.5a1 1 0 011-1h2zM10 .059a1 1 0 011 1v16a1 1 0 01-1 1H8a1 1 0 01-1-1v-16a1 1 0 011-1h2zM3 10.132H1v7h2v-7zm14-4.548h-2v11.5h2v-11.5zm-7-4.525H8v16h2v-16z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
