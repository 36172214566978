/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_case_info': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 1.143C6.004 1.143 1.143 6.003 1.143 12c0 5.996 4.86 10.857 10.857 10.857 5.996 0 10.857-4.86 10.857-10.857 0-5.996-4.86-10.857-10.857-10.857zM12 10c.277 0 .502.225.502.502v5.996a.502.502 0 01-1.004 0v-5.996c0-.277.225-.502.502-.502zm0-4a1 1 0 110 2 1 1 0 010-2z" id="svgicon__case_info_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__case_info_b" _fill="#fff"><use xlink:href="#svgicon__case_info_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__case_info_a"/><g mask="url(#svgicon__case_info_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
