/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_faq': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#FFF" fill-rule="nonzero"><path pid="0" d="M10 .5a9.5 9.5 0 100 19 9.5 9.5 0 000-19zm0 1a8.5 8.5 0 110 17 8.5 8.5 0 010-17z"/><path pid="1" d="M10.414 12.169c0-.682.163-1.204.49-1.567l.734-.726c.726-.763 1.089-1.536 1.089-2.32 0-.794-.237-1.418-.71-1.873-.473-.455-1.133-.683-1.98-.683-.816 0-1.478.223-1.987.67-.51.445-.768 1.031-.777 1.758h1.244c0-.408.14-.733.417-.975.278-.243.646-.364 1.103-.364.47 0 .83.134 1.076.4.247.267.37.636.37 1.107 0 .489-.188.946-.565 1.372l-.881.908c-.345.376-.574.72-.686 1.029-.112.309-.173.73-.182 1.264h1.245zM9.849 15c.242 0 .426-.066.551-.198a.699.699 0 00.188-.501.723.723 0 00-.188-.508c-.125-.137-.31-.205-.551-.205-.242 0-.425.068-.548.205a.73.73 0 00-.185.508c0 .201.061.368.185.5.123.133.306.199.548.199z"/></g>'
  }
})
