/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_business': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.042 3.532c1.357 0 2.467 1.054 2.467 2.368v1.55h3.507a2 2 0 012 2v9.018a2 2 0 01-2 2H4.984a2 2 0 01-2-2V9.45a2 2 0 012-2H8.49l.001-1.55c0-1.314 1.11-2.368 2.467-2.368h2.084zM7.027 8.45H4.984a1 1 0 00-1 1v9.019a1 1 0 001 1h2.043V8.448zm8.936 0H8.027v11.018h7.936V8.45zm3.053 0h-2.053v11.018h2.053a1 1 0 001-1V9.45a1 1 0 00-1-1zm-5.974-3.917h-2.084c-.816 0-1.467.618-1.467 1.368v1.55h5.018V5.9c0-.75-.651-1.368-1.467-1.368z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
