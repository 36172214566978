/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_request': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M19.93 6.769L17.302 1.14a.419.419 0 00-.418-.233H14.65a.465.465 0 100 .93h1.93l2.163 4.651h-2.651a3.047 3.047 0 00-2.79 1.791 2.163 2.163 0 01-1.954 1.233H8.65c-.834 0-1.594-.48-1.953-1.233a3.047 3.047 0 00-2.791-1.79H1.302L3.86 1.837h11.104a.465.465 0 100-.93H3.58a.442.442 0 00-.418.21L.093 6.744A.28.28 0 000 6.931v5.791a2.442 2.442 0 002.395 2.372h15.21A2.442 2.442 0 0020 12.722v-5.79a.349.349 0 00-.07-.163zm-.86 5.953a1.512 1.512 0 01-1.465 1.442H2.395A1.512 1.512 0 01.93 12.722V7.42h2.977c.834 0 1.595.479 1.953 1.232a3.047 3.047 0 002.791 1.791h2.698a3.047 3.047 0 002.79-1.79 2.163 2.163 0 011.954-1.233h2.977v5.302z" id="svgicon__request_a"/></defs><g transform="translate(2 4.087)" _fill="none" fill-rule="evenodd"><mask id="svgicon__request_b" _fill="#fff"><use xlink:href="#svgicon__request_a"/></mask><use _fill="#000" fill-rule="nonzero" xlink:href="#svgicon__request_a"/><g mask="url(#svgicon__request_b)" _fill="#FFF"><path pid="1" d="M-2-4h24v24H-2z"/></g></g>'
  }
})
