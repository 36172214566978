/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_chat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.147 6.518c1.016 0 1.845.829 1.845 1.845v7.339a1.848 1.848 0 01-1.845 1.845h-.527v2.212a.732.732 0 01-.481.694.73.73 0 01-.816-.208l-2.335-2.69H8.706a.502.502 0 110-1.004h7.51c.147 0 .286.061.38.171l2.024 2.34v-2.013c0-.278.225-.502.502-.502h1.025c.465 0 .845-.38.845-.845V8.363a.847.847 0 00-.845-.845.5.5 0 110-1zm-3.87-3.02c1.017 0 1.845.829 1.845 1.845v7.339a1.841 1.841 0 01-1.84 1.84h-8.27l-2.33 2.698a.745.745 0 01-.56.258l-.1-.007a.739.739 0 01-.642-.732v-2.212h-.527a1.848 1.848 0 01-1.845-1.845v-7.34c0-1.015.829-1.844 1.845-1.844zm0 1H3.854a.847.847 0 00-.845.845v7.335c0 .465.38.844.845.844h1.02c.278 0 .503.225.503.502v2.013L7.4 13.698a.502.502 0 01.38-.171h8.498c.465 0 .844-.38.844-.845v-7.34a.847.847 0 00-.844-.844zm-6.212 4.094a.596.596 0 110 1.192.596.596 0 010-1.192zm1.996 0a.596.596 0 110 1.192.596.596 0 010-1.192zm-3.992 0a.596.596 0 110 1.192.596.596 0 010-1.192z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
