/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M6.386 10.154c1.742-1.742 4.58-1.73 6.336.028a.502.502 0 01.003.71.502.502 0 01-.71-.003c-1.363-1.364-3.564-1.373-4.916-.021l-5.004 5.004c-1.352 1.351-1.343 3.553.02 4.916 1.364 1.364 3.566 1.373 4.917.021l5.684-5.684a.502.502 0 01.71.003.502.502 0 01.004.71l-5.684 5.684c-1.743 1.743-4.58 1.73-6.337-.027-1.758-1.757-1.77-4.594-.027-6.337zm8.773-8.772c1.742-1.742 4.58-1.73 6.337.027 1.757 1.757 1.77 4.595.027 6.337l-5.004 5.004c-1.743 1.742-4.58 1.73-6.337-.027a.502.502 0 01-.003-.71.502.502 0 01.71.003c1.364 1.363 3.565 1.373 4.917.02l5.004-5.003c1.351-1.352 1.342-3.553-.021-4.917-1.364-1.363-3.565-1.373-4.917-.02l-5.684 5.683a.502.502 0 01-.71-.003.502.502 0 01-.003-.71z" id="svgicon__link_a"/></defs><g transform="translate(1)" _fill="none" fill-rule="evenodd"><mask id="svgicon__link_b" _fill="#fff"><use xlink:href="#svgicon__link_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__link_a"/><g mask="url(#svgicon__link_b)" _fill="#3773F5"><path pid="1" d="M-1 1.406h24v24H-1z"/></g></g>'
  }
})
