/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M14 17.142v4.866a1 1 0 01-1 1h-.727a1 1 0 01-1-1V17.19c0-.016 0-.032.002-.048a7.5 7.5 0 112.725 0zm-1.363-1.922a5.455 5.455 0 100-10.909 5.455 5.455 0 000 10.91z" id="svgicon__search_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__search_b" _fill="#fff"><use xlink:href="#svgicon__search_a"/></mask><use _fill="#0ABF53" fill-rule="nonzero" transform="rotate(-45 12.637 12.637)" xlink:href="#svgicon__search_a"/><g mask="url(#svgicon__search_b)" _fill="#0ABF53"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
