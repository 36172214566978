/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'logo': {
    width: 33,
    height: 25,
    viewBox: '0 0 33 25',
    data: '<path pid="0" d="M11.466 6.819a.84.84 0 000 1.186l9.974 9.974a.841.841 0 001.187 0 .84.84 0 000-1.186L12.652 6.82a.841.841 0 00-1.186 0zM26.51 8.042c.33.022.612-.191.632-.477.02-.286-.232-.536-.56-.559-.33-.022-.612.191-.632.477-.02.286.231.536.56.559zM6.251 24.755a.84.84 0 001.185 0l5.42-5.42a.84.84 0 000-1.185.842.842 0 00-1.186 0l-5.42 5.42a.84.84 0 000 1.185zm5.51-23.324a.842.842 0 010-1.186.842.842 0 011.186 0L25.153 12.45c.36.361.662.76.905 1.184.116-1.037.46-2.214 1.292-3.22.51-.6 1.51-1.28 2.285-1.63-1.19-1.128-1.443-2.513-3.755-2.672-1.092-.076-2.135.174-3.093.666l.052-.027a.842.842 0 01-.76-1.494c1.033-.521 2.362-.926 3.916-.819 3.852.266 3.714 3.19 4.935 3.7 1.22.51 1.48 1.585 1.48 1.585-5.747-.27-4.238 5.269-6.125 9.161a5.66 5.66 0 01-1.132 1.621c-2.216 2.215-5.84 2.215-8.056 0l-5.987-5.988-9.698 9.698a.841.841 0 01-1.186 0 .842.842 0 010-1.186l9.975-9.974a.827.827 0 01.277-.184.84.84 0 011.265-.092l6.54 6.54a4.03 4.03 0 005.683 0 4.031 4.031 0 000-5.683L12.518 2.188l-.758-.757z" _fill="#FFFFFE" fill-rule="evenodd"/>'
  }
})
