/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_members': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M11.896 15.75c3.114 0 5.921 1.46 7.282 3.736a.5.5 0 01-.859.513c-1.172-1.962-3.648-3.249-6.423-3.249-2.808 0-5.308 1.318-6.462 3.315a.5.5 0 11-.865-.5c1.34-2.32 4.175-3.815 7.327-3.815zm-8.178-2c1.543 0 2.942.727 3.627 1.873a.5.5 0 11-.859.513c-.497-.831-1.564-1.386-2.768-1.386-1.22 0-2.296.568-2.785 1.414a.5.5 0 01-.866-.5c.675-1.169 2.088-1.914 3.65-1.914zm16.5 0c1.544 0 2.942.727 3.627 1.873a.5.5 0 11-.858.513c-.497-.831-1.564-1.386-2.769-1.386-1.219 0-2.296.568-2.784 1.414a.5.5 0 01-.866-.5c.675-1.169 2.088-1.914 3.65-1.914zM11.875 4.5a4.875 4.875 0 110 9.75 4.875 4.875 0 010-9.75zm-8.167 4a2.542 2.542 0 110 5.083 2.542 2.542 0 010-5.083zm16.5 0a2.542 2.542 0 110 5.083 2.542 2.542 0 010-5.083zm-8.333-3a3.875 3.875 0 100 7.75 3.875 3.875 0 000-7.75zm-8.167 4a1.542 1.542 0 100 3.083 1.542 1.542 0 000-3.083zm16.5 0a1.542 1.542 0 100 3.083 1.542 1.542 0 000-3.083z" id="svgicon__members_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__members_b" _fill="#fff"><use xlink:href="#svgicon__members_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__members_a"/><g mask="url(#svgicon__members_b)" _fill="#0ABF53"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
