/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_notification': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M9.324 21.43a.5.5 0 01.704-.064l.26.217a3.049 3.049 0 004.108-.187.5.5 0 01.708.708 4.049 4.049 0 01-5.455.247l-.26-.217a.5.5 0 01-.065-.704zM12.17 1a7.475 7.475 0 017.476 7.475v3.904a6.048 6.048 0 002.801 5.103l.135.086a1.5 1.5 0 01-.805 2.765H2.25a1.5 1.5 0 01-.751-2.798l.11-.064a6.182 6.182 0 003.087-5.351V8.475A7.475 7.475 0 0112.17 1zm0 1a6.475 6.475 0 00-6.475 6.475v3.645a7.182 7.182 0 01-3.586 6.216l-.11.065a.5.5 0 00.25.932h19.528a.5.5 0 00.269-.922l-.136-.086a7.048 7.048 0 01-3.264-5.946V8.475C18.646 4.9 15.746 2 12.17 2z" id="svgicon__notification_a"/></defs><g transform="translate(0 -.5)" _fill="none" fill-rule="evenodd"><mask id="svgicon__notification_b" _fill="#fff"><use xlink:href="#svgicon__notification_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__notification_a"/><g mask="url(#svgicon__notification_b)" _fill="#3773F5"><path pid="1" d="M0 .5h24v24H0z"/></g><path pid="2" d="M0 .5h24v24H0z"/></g>'
  }
})
