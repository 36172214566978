/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_marked': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm6.01 6.775a.75.75 0 010 1.06l-7.33 7.33a.894.894 0 01-.006.007l-.005.004-.083.084a.75.75 0 01-1.06 0l-.04-.042a.887.887 0 01-.05-.046l-3.358-3.359a.875.875 0 011.237-1.237l2.917 2.916 6.718-6.717a.75.75 0 011.06 0z" id="svgicon__marked_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__marked_b" _fill="#fff"><use xlink:href="#svgicon__marked_a"/></mask><use _fill="#3773F5" xlink:href="#svgicon__marked_a"/><g mask="url(#svgicon__marked_b)" _fill="#88DE31"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
