/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_profile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M18 0a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4h14zm0 1H4a3 3 0 00-3 3v16a3 3 0 003 3h14a3 3 0 003-3V4a3 3 0 00-3-3zm-2 18a.5.5 0 110 1H6a.5.5 0 110-1zM6 16h10a.5.5 0 01.09.992L16 17H6a.5.5 0 01-.09-.992L6 16h10zm5.012-4.955c1.883 0 3.586.886 4.416 2.276a.5.5 0 11-.858.513c-.643-1.076-2.014-1.789-3.558-1.789-1.562 0-2.946.73-3.578 1.825a.5.5 0 01-.866-.5c.819-1.418 2.539-2.325 4.444-2.325zM11 4.5a3.045 3.045 0 110 6.09 3.045 3.045 0 010-6.09zm0 1a2.045 2.045 0 100 4.09 2.045 2.045 0 000-4.09z" id="svgicon__profile_a"/></defs><g transform="translate(1)" _fill="none" fill-rule="evenodd"><mask id="svgicon__profile_b" _fill="#fff"><use xlink:href="#svgicon__profile_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__profile_a"/><g mask="url(#svgicon__profile_b)" _fill="#3773F5"><path pid="1" d="M-1 0h24v24H-1z"/></g></g>'
  }
})
