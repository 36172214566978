/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_create_chat': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M22.839 1.644a1.232 1.232 0 00-.248-.374l-.902-.9a1.289 1.289 0 00-1.807 0l-.403.457-.359.406 2.603 2.587.868-.751a1.24 1.24 0 00.248-.373 1.255 1.255 0 000-1.052zM18.006 18.94a2.994 2.994 0 01-3.001 2.999H4a2.994 2.994 0 01-3.001-3V7.943a3 3 0 013-3h10.005l1-1H4.001A4 4 0 000 7.95v10.985a4.002 4.002 0 004.007 4.006H15a4.006 4.006 0 004.007-4V7.944l-1 .999v10zM20.364 3.45l-9.875 9.8-.23-.228-.637-.633 9.874-9.802-.434-.433-.433-.43-4.938 4.9-4.938 4.9-.506 1.824-.507 1.82h.001l3.616-1.055 9.875-9.802-.46-.456-.408-.405z" id="svgicon__create_chat_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__create_chat_b" _fill="#fff"><use xlink:href="#svgicon__create_chat_a"/></mask><use _fill="#3773F5" xlink:href="#svgicon__create_chat_a"/><g mask="url(#svgicon__create_chat_b)" _fill="#3773F5"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
