/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'default-white': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<defs><path pid="0" d="M22.839 1.644a1.232 1.232 0 00-.248-.374l-.902-.9a1.289 1.289 0 00-1.807 0l-.403.457-.359.406 2.603 2.587.868-.751a1.24 1.24 0 00.248-.373 1.255 1.255 0 000-1.052zm-5.838 15.36A4 4 0 0113 21H6c-2.21 0-4-1.795-4-4v-7c0-2.209 1.787-4 3.995-4H12l2-2H5.998A5.996 5.996 0 000 9.999V17A5.996 5.996 0 005.999 23H13A5.997 5.997 0 0019 17.002V9l-2 1.997v6.006zm1.628-15.281l-9.876 9.8-1.013 3.644 3.617-1.055 9.875-9.802-2.603-2.587z" id="svgicon_default-white_a"/></defs><g _fill="none" fill-rule="evenodd"><g><mask id="svgicon_default-white_b" _fill="#fff"><use xlink:href="#svgicon_default-white_a"/></mask><use _fill="#3773F5" xlink:href="#svgicon_default-white_a"/><g mask="url(#svgicon_default-white_b)" _fill="#FFF"><path pid="1" d="M0 0h24v24H0z"/></g></g></g>'
  }
})
