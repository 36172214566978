/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_checked': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm6.187 5.952a.5.5 0 010 .707l-7.778 7.778a.498.498 0 01-.227.13l-.084.015h-.085a.498.498 0 01-.311-.145l-3.89-3.889a.5.5 0 11.708-.707l3.535 3.535 7.425-7.424a.5.5 0 01.707 0z" id="svgicon__checked_a"/></defs><g _fill="none" fill-rule="evenodd"><mask id="svgicon__checked_b" _fill="#fff"><use xlink:href="#svgicon__checked_a"/></mask><use _fill="#3773F5" xlink:href="#svgicon__checked_a"/><g mask="url(#svgicon__checked_b)" _fill="#88DE31"><path pid="1" d="M0 0h24v24H0z"/></g></g>'
  }
})
