/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_images': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M20.317 0a4 4 0 014 4l-.001 12.829.34.313-.34.368V20a4 4 0 01-4 4h-16a4 4 0 01-4-4v-3.991L0 15.623l.316-.259V4a4 4 0 014-4h16zM7.65 10.652l-6.334 5.185V20a3 3 0 003 3h16a3 3 0 003-3v-2.732l-3.82-3.517-4.847 2.871-6.999-5.97zM20.317 1h-16a3 3 0 00-3 3l-.001 10.545 6.348-5.197 7.09 6.05 4.873-2.886 3.689 3.397V4a3 3 0 00-3-3zm-4 2.5a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm0 1a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" id="svgicon__images_a"/></defs><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__images_a" transform="translate(-.317)"/>'
  }
})
