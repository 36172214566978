/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_customer_notes': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<defs><path pid="0" d="M15 .5a4.5 4.5 0 014.495 4.288L19.5 5v6a.5.5 0 01-.992.09L18.5 11V5a3.5 3.5 0 00-3.308-3.495L15 1.5H4.5a3.5 3.5 0 00-3.495 3.308L1 5v14a3.5 3.5 0 003.308 3.495l.192.005H10a.5.5 0 01.09.992L10 23.5H4.5a4.5 4.5 0 01-4.495-4.288L0 19V5A4.5 4.5 0 014.288.505L4.5.5H15zm2.012 19.926c1.883 0 3.586.885 4.416 2.275a.5.5 0 11-.858.513c-.643-1.075-2.014-1.788-3.558-1.788-1.562 0-2.946.73-3.578 1.824a.5.5 0 11-.866-.5c.819-1.417 2.539-2.324 4.444-2.324zM17 13.881a3.045 3.045 0 110 6.09 3.045 3.045 0 010-6.09zm0 1a2.045 2.045 0 100 4.09 2.045 2.045 0 000-4.09zM11 15a.5.5 0 01.09.992L11 16H4.5a.5.5 0 01-.09-.992L4.5 15H11zm4-4a.5.5 0 01.09.992L15 12H4.5a.5.5 0 01-.09-.992L4.5 11H15zm0-4a.5.5 0 01.09.992L15 8H4.5a.5.5 0 01-.09-.992L4.5 7H15z" id="svgicon__customer_notes_a"/></defs><g transform="translate(2)" _fill="none" fill-rule="evenodd"><mask id="svgicon__customer_notes_b" _fill="#fff"><use xlink:href="#svgicon__customer_notes_a"/></mask><use _fill="#3773F5" fill-rule="nonzero" xlink:href="#svgicon__customer_notes_a"/><g mask="url(#svgicon__customer_notes_b)" _fill="#3773F5"><path pid="1" d="M-2 0h24v24H-2z"/></g></g>'
  }
})
