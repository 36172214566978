/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '_location_pin': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 4.5c4.97 0 9 3.863 9 8.627a8.33 8.33 0 01-1.414 4.645l.035.044-7.699 9.684-7.004-9.048a8.693 8.693 0 01-.295-.381l-.15-.194.007-.008A8.335 8.335 0 017 13.127C7 8.363 11.03 4.5 16 4.5zm0 5.427a3.5 3.5 0 100 7 3.5 3.5 0 000-7z" _fill="#FFF" fill-rule="nonzero"/>'
  }
})
